import { IProvider } from 'interfaces/prescriptions/prescription-provider'
import { validateMobilePhone } from './form-validation'

/**
 * Checks is a provider object has all required info
 * to be valid
 * @param {IProvider} provider
 * @returns {boolean}
 */
export function isProviderInformationValid(provider: IProvider, { validatePhone = true } = {}): boolean {
    if (!provider?.first_name) {
        return false
    }

    if (validatePhone) {
        if (!validateMobilePhone(provider?.phone_number)) {
            return false
        }
    }

    return true
}
